<template>
  <div class="qianyue1List_lists">
    <div v-if="list.length > 0">
      <div class="qianyueList_list" v-for="(item, index) in list" :key="index">
        <div class="qianyueList_name show-overflow">{{ item.name }}</div>
        <div class="qianyueList_name qianyueList_flex" @click="toQianyue(item)">
          <div class="qianyueList_time">{{ item.create_time }}</div>
          <div>
            <span
              v-if="item.status == 7"
              class="qianyueList_status qianyueList_isStatus"
            >
              {{ state[item.status] }}
            </span>
            <span
              v-else-if="item.status == 3"
              class="qianyueList_status qianyueList_statused"
            >
              {{ state[item.status] }}
            </span>
            <span class="qianyueList_status qianyueList_error" v-else>
              {{ state[item.status] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="message_noinfo">暂无相关信息</div>
  </div>
</template>
<script>
import { formatMinute } from "../utils/tool";
export default {
  data() {
    return {
      list: [
        // { name: "张三的合同", time: "2022-01-14 10:54:38", status: 0 },
        // { name: "张三的合同", time: "2022-01-14 10:54:38", status: 1 },
      ],
      pid: "",
      state: [],
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    let id = localStorage.getItem("id");
    this.pid = pid;
    // 测试
    // this.pid=53738;
    this.id = id;
    if (this.pid) {
      this.getList();
    }
    this.GetStateData();
  },
  methods: {
    /*获取板块状态码*/
    GetStateData() {
      var model = "contract";
      this.$axios
        .get("/tag/getList?table=molds&molds=" + model)
        .then((res) => {
          if (res.data.code === 0) {
            this.state = res.data.data[0].state;
          } else {
            this.$toast({ messsage: res.data.msg });
          }
        })
        .catch(() => {});
    },
    getList() {
      //查询合同
      this.$axios
        .get(
          '/tag/getlist?table=contract&where={"person_id":' +
            this.pid +
            ',"status":{"symbol": "<=", "val":8}}'
        )
        .then((res) => {
          this.list = res.data.data;
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].create_time = formatMinute(this.list[i].create_time);
          }
        })
        .catch(() => {});
    },
    toQianyue(val) {
      if (val.status == 7) {
        this.$toast({ message: "前往签约页面" });
        this.$axios
          .get("/person/getflowsUrl?pid=" + this.pid)
          .then((res) => {
            if (res.data.success) {
              window.location.href = res.data.data.url;
            } else {
              this.$toast({ message: res.data.msg });
            }
          })
          .catch(() => {});
      } else if (val.status == 3) { 
        window.location.href = val.filelist;
      } else {
        this.$toast({ message: "合同状态无法签约，请联系客服~" });
      }
    },
  },
};
</script>
<style scoped>
.message_noinfo {
  text-align: center;
  margin-top: 1rem;
}
.qianyue1List_lists {
  width: calc(100% - 1rem);
  margin: 0 auto;
}
.qianyueList_list {
  border-bottom: 0.0625rem solid #ccc;
  height: 3.75rem;
}
.qianyueList_name {
  line-height: 1.825rem;
  font-size: 0.875rem;
}
.qianyueList_flex {
  display: flex;
  justify-content: space-between;
}
.qianyueList_time {
  font-size: 0.75rem;
}
.qianyueList_status {
  font-size: 1rem;
}
.qianyueList_isStatus {
  color: #f59a23;
}
.qianyueList_statused {
  color: #059b2f;
}
.qianyueList_error {
  color: #f00;
}
/* 0: "草稿"   
1: "法务待审核" 
2: "驳回"
3: "签约完成"
4: "经理待审核"
5: "审核通过"
6: "合同结束"
7: "待签约"
8: "签约失败" */

.show-overflow {
  width: 75%;
  overflow: hidden; /* 超出元素宽度范围时候不使用滚动条 */
  text-overflow: ellipsis; /* 文本溢出时显示省略号 */
  white-space: nowrap; /* 文本单行显示不换行； */
}
</style>
<style>
.van-toast__text {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
}
</style>
